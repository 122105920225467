met-popper-content .popper-container {
  border-radius: none;
  border: none;
  box-shadow: none;
  padding: 0;
  z-index: 10;
  .popper-arrow {
    display: none;
  }
}

@import '@devop/ngx-metronic/assets/sass/init';

// Components
@import 'metronic-components';

// Layout
@import '@devop/ngx-metronic/assets/sass/layout/base/layout';
@import '@devop/ngx-metronic/assets/sass/layout/layout';
//@import 'node_modules/@devop/ngx-metronic/assets/sass/plugins.scss';
//@import 'node_modules/@devop/ngx-metronic/datepicker/assets/bs-datepicker.scss';
