@import 'assets/sass/metronic.scss';

.h-max {
  height: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

.fade:not(.show) {
  opacity: 0;
}

.card-empty {
  display: flex;

  .empty-message {
    align-self: center;
    text-align: center;
    flex: 1;

    strong {
      color: black;
    }
  }
}
